<template>
  <div>
    <div class="misc-inner">
      <h2 class="welcome-smarthub">
        <b>Welcome</b> <span>|</span> <i>Make the Earth
          your shareholder</i>
      </h2>
      <div class="w-100 text-center">
        <b-img
          fluid
          :src="imgUrl"
          alt="Welcome"
        />
      </div>
    </div>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-container>
          <b-row>
            <b-col
              v-for="style in latestData"
              :key="style.id"
              class="style-card-box"
            >
              <b-link
                :to="{
                  name: 'apps-styles-edit', params: { id: style.id },
                }"
              >
                <b-card>
                  <b-card-text
                    text-tag="div"
                  >
                    <div
                      v-if="style.cover_image"
                      class="image-style"
                    >
                      <img :src="style.cover_image">
                    </div>
                    <div
                      v-if="!style.cover_image"
                      class="d-flex justify-content-center align-items-center bg-light noimage-style"
                    >
                      <feather-icon
                        v-if="!style.cover_image"
                        :id="`card-${style.id}-cover`"
                        icon="EyeOffIcon"
                        size="128"
                      />
                    </div>
                    <h4 class="card-title mt-2">
                      {{ style.name }}
                    </h4>
                    <h6 class="card-subtitle text-muted">
                      {{ style.ref }}
                    </h6>

                    <div
                      v-if="style.latest_sample_request"
                    >
                      <b-link
                        :to="{ name: 'apps-sample-requests-edit',
                               params: { id: style.latest_sample_request.id },
                               hash: '#1',}"
                      >
                        <div class="d-flex flex-row justify-content-start align-items-end">
                          <div>
                            <feather-icon
                              icon="BoxIcon"
                              size="16"
                            />
                          </div>
                          <div class="text-muted ml-1 align-self-end">
                            {{ style.latest_sample_request.sample_status }}
                          </div>
                        </div>
                      </b-link>
                    </div>
                  </b-card-text>
                </b-card>
              </b-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="misc-inner">
        <div class="w-100 text-left p-2">
          <b-link
            :to="{ name: 'apps-styles-list' }"
          >
            <h2 class="card-title mt-2 manage-styles-smarthub">
              <b>Manage Styles</b>
            </h2>
          </b-link>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from '@axios'
import {
  BCard, BCardText, BContainer, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'
import {
  onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BCardText,
    BContainer,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/smarthub-home.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/smarthub-home.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  setup() {
    const latestData = ref([])
    const STORE_MODULE_NAME = 'home'

    const homeStore = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {},
      actions: {
        fetchLatest() {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/styles/latest/')
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
      },
    }

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, homeStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const fetchLatest = () => {
      store.dispatch('home/fetchLatest')
        .then(response => { latestData.value = response.data })
    }

    onMounted(fetchLatest)

    return {
      latestData,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-misc.scss';
.dark-layout .jumbotron {
  background-color: #283046;
}
.per-page-selector {
  width: 90px;
}
.style-card-box {
  flex: 300px 0;
}
.image-style img {
  object-fit: cover;
  width: 100%;
  height: 280px;
  vertical-align: middle;
  border-radius: 15px;
}
.noimage-style {
  margin: 0 auto 0 auto;
  height: 280px;
  border-radius: 15px;
}
.card {
  background-color: transparent;
}
.welcome-smarthub b {
  font-weight: 500;
  color: #333;
  letter-spacing: 0.1em;
}
.welcome-smarthub span {
  color: #CCC;
  font-size: 1.5rem;
  padding-right: 1rem;
}
.welcome-smarthub i {
  color: #333;
  font-weight: 300;
  font-size: 1rem;
}
.manage-styles-smarthub {
  font-weight: 500;
  color: #333;
  letter-spacing: 0.1em;
}
</style>
